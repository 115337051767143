import classNames from "classnames";
import {SbBlokData, storyblokEditable, StoryblokComponent} from "@storyblok/react";
import Image from "next/image";

const featuredTestimonial = {
    body: 'We really enjoy working with Chris at Pacific Codeline and appreciate the quick turnarounds and ‘can do’ attitude to solve things along the way.',
    author: {
        name: 'David Ferguson',
        handle: 'EVP, OPERATIONS AND PRODUCTION at THE BUDDY GROUP',
        imageUrl: '/images/testimonials/df.png',
        logoUrl: '/images/testimonials/thebuddygroup.jpg',
    },
}
const testimonials = [
    [
        [
            {
                body: 'A true programmer for custom applications. This is where you go when you need real professional programming. Thank you!',
                author: {
                    name: 'Carlos Cota',
                    handle: 'Vice President at Transparency Real Estate Services Inc.',
                    imageUrl:
                        '/images/testimonials/cc.png',
                    // logoUrl: 'https://tailwindui.com/img/logos/savvycal-logo-gray-900.svg',
                },
            },

            {
                body: 'I highly recommend Pacific Codeline. We have been very well advised regarding the development of software  for our company and appreciate the experience of Pacific Codeline.',
                author: {
                    name: 'Lars Fischer',
                    handle: 'Kämpken & Fischer GmbH',
                    imageUrl: '/images/testimonials/lf.jpg',
                },
            },
            {
                body: 'Thank you for the great and reliable cooperation. And also for the ideas and commitment in the projects. I hope we will implement many more great projects together.',
                author: {
                    name: 'Tim Sieber',
                    handle: 'Sieber and Partner',
                    imageUrl:
                        '/images/testimonials/ts.png',
                    // logoUrl: 'https://tailwindui.com/img/logos/savvycal-logo-gray-900.svg',
                },
            },

            {
                body: 'The cooperation with [Pacific Codeline] is very easy and professional. He programmed my private news project "Industrial-Internet.de" for me. It was extremely fast and the result is convincing.',
                author: {
                    name: 'Carsten Knop',
                    handle: 'FAZ, Editor in Chief',
                    imageUrl: '/images/testimonials/ck.png',

                },
            },
            {
                body: 'The cooperation is great. Problem-free communication, fast response time and every customer request is always addressed. Definitely recommended!',
                author: {
                    name: 'Maximilian Kares',
                    handle: 'GedK Gelectra GmbH',
                    imageUrl: '/images/testimonials/mk.png',
                    logoUrl: '/images/testimonials/gedk.png',
                },
            },

        ],
        [
            {
                body: '[Pacific Codeline] has assisted us with various process digitization projects that have enabled us to save costs and gain competitive advantages to this day. Thank you for the intensive sparring before and the great service after the launch of the commissioned developments!',
                author: {
                    name: 'Holger Schlapper',
                    handle: 'CEO at A&B Events GmbH',
                    imageUrl: '/images/testimonials/hs.png',

                },
            },
            {
                body: 'We needed an interface between Zoho API and Digistore and we are very satisfied. The working relationship with [Pacific Codeline] was very pleasant!',
                author: {
                    name: 'Franz Hildebrand',
                    handle: 'Quantfloor GmbH & Co. KG',
                    imageUrl: '/images/unknown_user.png',

                },
            },

            // {
            //     body: 'All of our questions were answered professionally and quickly. Thanks very much!',
            //     author: {
            //         name: 'Malte Kroell',
            //         handle: '',
            //         imageUrl: '/images/unknown_user.png',
            //
            //     },
            // },
            {
                body: 'The service provider [...] in the field of software development is a very reliable partner. We have had very good experiences and recommend the digital agency [..] without reservation and look forward to further cooperation.',
                author: {
                    name: 'Michael Müller',
                    handle: '',
                    imageUrl: '/images/testimonials/mm.png',
                },
            },


        ],
    ],
    [
        [
            // {
            //     body: 'This is a young, very committed team that I can recommend without reservation for all services related to the Internet.',
            //     author: {
            //         name: 'Claus B.',
            //         handle: '',
            //         imageUrl: '/images/testimonials/cb.png',
            //
            //     },
            // },
            // {
            //     body: 'Has a wide range of knowledge and was able to advise us very well. A clear recommendation!',
            //     author: {
            //         name: 'Anonymous',
            //         handle: '',
            //         imageUrl: '/images/unknown_user.png',
            //
            //     },
            // },
            // {
            //     body: 'The cooperation is great. Problem-free communication, fast response time and every customer request is always addressed.\n' +
            //         'Definitely recommended!',
            //     author: {
            //         name: 'Kilian Lipp',
            //         handle: '',
            //         imageUrl: '/images/unknown_user.png',
            //
            //     },
            // },
            {
                body: '[Pacific Codeline] surpassed my expectations. I highly recommend his services for anyone in need of a skilled and reliable web developer.',
                author: {
                    name: 'Marta W',
                    handle: '',
                    imageUrl: '/images/testimonials/mw.png',

                },
            },
            {
                body: 'Thank you for the cooperation, which was smooth and successful for me.',
                author: {
                    name: 'Max Ehlers',
                    handle: 'UNESCO-UNEVOC',
                    imageUrl: '/images/testimonials/me.png',

                },
            },
            {
                body: 'For our various websites & web applications, we have been regularly using the services of [Pacific Codeline] for many years and are very satisfied. We appreciate the uncomplicated and familiar cooperation, the broad know-how as well as the solution-oriented way they work.',
                author: {
                    name: 'Abdul Lamghari',
                    handle: 'GFU Cyrus AG',
                    imageUrl: '/images/testimonials/al.png',

                },
            },
        ],
        [
            {
                body: 'I recently worked with Pacific Codeline LLC developing enhancements to our e-commerce website, including a more streamlined efficient checkout process. Chris was very professional and easy to work with.',
                author: {
                    name: 'Brad Handy',
                    handle: 'President at Lettering.com Inc.',
                    imageUrl: '/images/testimonials/bh.jpg',

                },
            },
            {
                body: "Pacific Codeline LLC has delivered ready-to-use form fields, marking the project's success. The team is very helpful, proactive, and flexible, delivering additional work at no extra cost. Moreover, they've been timely, communicative, and highly responsive, and their development skills are excellent.",
                author: {
                    name: 'CEO of toolboxx-media',
                    handle: 'Thomas Koeller',
                    imageUrl: '/images/testimonials/tk.jpg',
                },
            },
            {
                body: 'Successful and fast project processing. Thank you and keep it up.',
                author: {
                    name: 'Christine S., Assistant to the Executive Management',
                    handle: 'Kamphausen Digital',
                    imageUrl: '/images/testimonials/cs.jpg',
                },
            },
            {
                body: 'A very good agency for high-quality and complex web developments. The results were always presented and processed based on milestones. I am very satisfied and can warmly recommend the agency, only very few other companies can match the quality and speed. Keep it up!',
                author: {
                    name: 'Stanislaus Jampolski',
                    handle: 'CGI Deutschland GmbH & Co. KG',
                    imageUrl: '/images/testimonials/sj.png',
                },
            },
            // {
            //     body: 'Fast delivery of results and excellent communication in the project environment. Gladly recommended.',
            //     author: {
            //         name: 'Anonymous',
            //         handle: '',
            //         imageUrl: '/images/unknown_user.png',
            //
            //     },
            // },
            // {
            //     body: 'I was allowed to encounter Webzeile GmbH (previous German corporation of Mr. Heike) as a professional and competent partner. Our webshop was thoroughly and carefully accompanied and implemented from planning to successful implementation, I am definitely very satisfied, thank you.',
            //     author: {
            //         name: 'Anonymous',
            //         handle: '',
            //         imageUrl: '/images/unknown_user.png',
            //
            //     },
            // },

        ]
    ],
]

export default function Testimonials({blok}: { blok: SbBlokData }) {
    return (
        <div className="relative isolate bg-white pt-24 pb-32 sm:pt-32" {...storyblokEditable(blok)}>
            <div
                className="absolute inset-x-0 top-1/2 -z-10 -translate-y-1/2 transform-gpu overflow-hidden opacity-10 blur-3xl
                bg-gradient-to-r from-gray-200 via-gray-400 to-gray-600 h-full
                ">

            </div>
            <div
                className="absolute inset-x-0 top-0 -z-10 flex transform-gpu overflow-hidden pt-16 opacity-25 blur-3xl sm:pt-24 xl:justify-end">
                <svg
                    viewBox="0 0 1313 771"
                    aria-hidden="true"
                    className="ml-[-22rem] w-[82.0625rem] flex-none origin-top-right rotate-[30deg] xl:ml-0 xl:mr-[calc(50%-12rem)]"
                >
                    <use href="#bc169a03-3518-42d4-ab1e-d3eadac65edc"/>
                </svg>
            </div>
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="mx-auto max-w-xl text-center">
                    <h2 className="text-lg font-semibold leading-8 tracking-tight text-secondary">Testimonials</h2>
                    <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                        We Have Worked with Dozens of Amazing People.
                    </p>
                </div>
                <div
                    className="mx-auto mt-16 grid max-w-2xl grid-cols-1 grid-rows-1 gap-8 text-sm leading-6 text-gray-900 sm:mt-20 sm:grid-cols-2 xl:mx-0 xl:max-w-none xl:grid-flow-col xl:grid-cols-4">
                    <figure
                        data-aos={'fade-up'}
                        className="col-span-2 hidden sm:block sm:rounded-2xl sm:bg-white sm:shadow-lg sm:ring-1 sm:ring-gray-900/5 xl:col-start-2 xl:row-end-1 hover:ring-1 hover:ring-secondary">
                        <blockquote className="p-12 text-xl font-semibold leading-8 tracking-tight text-gray-900">
                            <p>{`“${featuredTestimonial.body}”`}</p>
                        </blockquote>
                        <figcaption className="flex items-center gap-x-4 border-t border-gray-900/10 py-4 px-6">
                            <Image width={40} height={40}
                                   className="h-10 w-10 flex-none rounded-full bg-gray-50"
                                   src={featuredTestimonial.author.imageUrl}
                                   alt=""
                            />
                            <div className="flex-auto">
                                <div className="font-semibold">{featuredTestimonial.author.name}</div>
                                <div className="text-gray-600">{`${featuredTestimonial.author.handle}`}</div>
                            </div>
                            {featuredTestimonial.author.logoUrl && (
                                <Image width={48} height={48} className="flex-none"
                                       src={featuredTestimonial.author.logoUrl} alt=""/>
                            )}
                        </figcaption>
                    </figure>
                    {testimonials.map((columnGroup, columnGroupIdx) => (
                        <div key={columnGroupIdx} className="space-y-8 xl:contents xl:space-y-0">
                            {columnGroup.map((column, columnIdx) => (
                                <div
                                    key={columnIdx}
                                    className={classNames(
                                        (columnGroupIdx === 0 && columnIdx === 0) ||
                                        (columnGroupIdx === testimonials.length - 1 && columnIdx === columnGroup.length - 1)
                                            ? 'xl:row-span-2'
                                            : 'xl:row-start-1',
                                        'space-y-8'
                                    )}
                                >
                                    {column.map((testimonial, testimonialIndex) => (
                                        <figure
                                            data-aos={'fade-up'}
                                            data-aos-delay={((testimonialIndex + columnIdx) * 100)}
                                            key={testimonial.author.name + testimonialIndex}
                                            className="rounded-2xl hover:ring-1 hover:ring-secondary bg-white p-6 shadow-lg ring-1 ring-gray-900/5"
                                        >
                                            <blockquote className="text-gray-900">
                                                <p>{`“${testimonial.body}”`}</p>
                                            </blockquote>
                                            <figcaption className="mt-6 flex items-center gap-x-4">
                                                <Image
                                                    width={48} height={48}
                                                    className="rounded-full bg-gray-50"
                                                    src={testimonial.author.imageUrl} alt=""/>
                                                <div>
                                                    <div className="font-semibold">{testimonial.author.name}</div>
                                                    <div
                                                        className="text-gray-600">{`${testimonial.author.handle}`}</div>
                                                </div>
                                            </figcaption>
                                        </figure>
                                    ))}
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}
